.footer-background {
    background-color: #0079A5;
}
.footer-background {
    background-color: #0079A5;
    padding-top: 80px;
    padding-bottom: 60px;
}
.footer-text {
    color: #fff;
}
.footer-logo {
    margin-bottom: 20px;
}
#Footer-menu a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 20px;
}
.Footer-heading {
    color: #fff;
}
.Footer-heading {
    color: #fff;
    margin-bottom: 30px;
}
.footer-section2 {
    text-align: center;
}
.footer-section3 p {
    color: #fff;
    font-size: 18px;
}
/* Responsive  */
@media only screen and (max-width: 600px) {
.footer-logo {
    margin-bottom: 20px;
    width: 20%;
}
.footer-section2 {
    text-align: left;
}
}
@media only screen and (max-width: 1200px) {
.footer-logo {
    width: 50px;
}
}