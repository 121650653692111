.logo-benefit {
    width: 100%;
}
.heading-benefits-section {
    padding-top: 80px;
    padding-bottom: 20px;
}
.Headings {
    font-size: 35px;
    font-weight: bold;
    color: #0079a5;
}
.border {
    margin-top: 28px;
    height: 4px;
    background-color: #0079a5;
}
.border-line-section {
    padding-right: 3px;
}
.benefits-content {
    padding-bottom: 80px;
}
.paragraph-text {
    font-size: 17px;
    line-height: 35px;
    text-align: justify;
    color: #3a3a3c;
}
.Right-section {
    margin-top: 110px;
}
.right-heading {
    margin-bottom: 25px;
}
.Right-text-section {
    margin-top: 150px;
}
.left-text-section {
    margin-top: 150px;
}
/* Responsive  */
@media only screen and (max-width: 600px) {
    .Logo-section {
        text-align: center;
        margin-top: 18px;
        margin-bottom: 18px;
    }
    #menu {
        margin: 0;
    }
    .Headings {
        font-size: 26px !important;
    }
    .heading-benefits-section {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .Right-text-section {
        margin-top: 30px;
    }
    .left-text-section {
        margin-top: 70px;
    }
    .right-image-section {
        margin-bottom: 90px;
    }  
}
@media only screen and (max-width: 992px)  {
    img{
        width: 100%;
    }
    .Right-text-section {
        margin-top: 0px;
    }
    .Headings {
        font-size: 22px;
    }
    .left-text-section {
        margin-top: 0px;
    }
    .divided-row {
        margin-bottom: 70px;
    }
    .heading-benefits-section{
        padding-top: 30px;
    }
    .Submit-button {
        width: calc(100% - 76%);
    }
    .footer-logo {
        width: 60px;
    }
}
@media only screen and (max-width: 1200px)  {
    img{
        width: 100%;
    }
    .Right-text-section {
        margin-top: 0px;
    }
    .left-text-section {
        margin-top: 0px;
    }
    .divided-row {
        margin-bottom: 90px;
    }
}