.colorMargin {
    width: 20px;
    fill: #fff;
    margin-right: 5px;
}
.phone-number {
    text-decoration: none;
    color: #fff;
    font-family: poppins;
}
.top-header-background{
    background-color: #0079a5;
    font-family: poppins;
}
.phone-number:hover {
    color: #fff;
}
.top-header-container {
    padding: 20px 0;
    color: #fff;
    font-family: poppins;
    padding-bottom: 0px;
}
.social-media-icons .colorMargin {
    margin-right: 22px;
}
.social-media-icons {
    text-align: right;
}
/* Responsive  */
@media only screen and (max-width: 600px) {
    .top-header-row{
        text-align: center;
    }
    #menu {
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .social-media-icons {
        text-align: center !important;
        margin-bottom: 20px;
    }
    .Logo {
        width: 19%;
    }
    .Phone {
        margin-bottom: 1rem;
    }
}
@media only screen and (max-width: 992px) {
.social-media-icons .colorMargin {
    margin-right: 12px;
}
}
