
.c-stepper__item {
    position: relative;
    display: flex;
    gap: 1rem;
    padding-bottom: 4rem;
}
.c-stepper__item ::before {
  content: "";
  flex: 0 0 clamp(1.5rem, 5vw, 3rem);
  height: clamp(1.5rem, 5vw, 3rem);
  border-radius: 50%;
  background-color: lightgrey;
}
  
.c-stepper__item:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 0;
    top: calc(clamp(1.5rem, 5vw, 3rem) + clamp(0.25rem, 2vw, 0.5rem));
    bottom: var(--spacing);
    z-index: -1;
    transform: translateX(calc(clamp(1.5rem, 5vw, 3rem) / 2));
    width: 2px;
    background-color: #e0e0e0;
  }
  
  .c-stepper__title {
    font-weight: bold;
    font-size: clamp(1rem, 4vw, 1.25rem);
    margin-bottom: clamp(0.85rem, 2vmax, 1rem);
  }
  
  .c-stepper__desc {
    color: grey;
    font-size: clamp(0.85rem, 2vmax, 1rem);
  }
  
  .c-stepper__content {
    max-width: 700px;
  }
  .wrapper {
    max-width: 1000px;
    margin: 32px auto 0;
  }
  .contact-heading {
    color: #434343 !important;
    margin-bottom: 20px;
}
.contact-form input {
  width: 45%;
  margin-right: 30px;
  height: 50px;
  margin-bottom: 20px;
  padding-left: 10px;
}
.contact-form textarea {
  width: 94%;
  height: 78px;
  padding-left: 10px;
  padding-top: 10px;
}
.Contact-form-con {
  padding-top: 80px;
  padding-bottom: 80px;
}
.right-para {
  margin-bottom: 20px;
  display: block;
  text-transform: capitalize;
  width: 94%;
  line-height: 24px;
}
.buttn {
  margin-top: 30px;
  width: 20%;
  text-align: center;
  padding: 10px !important;
}
@media only screen and (max-width: 600px) {
.contact-form input {
  width: 100%;
}
.contact-form textarea{
  width: 100%;
}
.right-para{
  margin-top: 50px;
}
}
@media only screen and (max-width: 992px) {
.contact-form input {
  width: 100%;
}
.contact-form textarea {
  width: 100%;
}
}
@media only screen and (max-width:1200px) {
  .contact-form input {
    width: 100%;
  }
  .contact-form textarea {
    width: 100%;
  }
}