.Banner-Heading-fact {
    line-height: 50px;
    margin-bottom: 27px;
    text-transform: uppercase;
}
.banner-section-fact {
    padding-top: 40px;
    padding-bottom: 40px;
}
.Truck-banner-image {
    width: 100%;
}
.left-bnnr-section-fact {
    margin-top: 100px;
}
.paragraph3 {
    text-align: right !important;
    margin-top: 26px;
}
.bottom-margin {
    margin-bottom: 100px;
}
.fact-list b {
    font-family: poppins;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 20px;
    display: block;
}
.fact-list-ul {
    padding-left: 0px;
}
.fact-list-ul li {
    margin-bottom: 10px;
    font-size: 18px;
    list-style: none;
}
.fact-Section3-back {
    background: url(/public/images/back-truck3.png);
    padding: 60px 0px;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
}
.heading-benefits-section-truck {
    text-align: right;
}
.right {
    text-align: right;
}
.Fact-Section2 {
    background-color: #f4f4f4;
    padding-top: 80px;
    padding-bottom: 80px;
}
.benefits-content-trucking  {
    padding-bottom: 80px;
}
.section4 img {
    display: block;
    text-align: center;
    margin: auto;
    width: 60px;
}
.section4 {
    text-align: center;
    font-family: poppins;
}
.section4 b {
    color: #3A3A3C;
    text-transform: uppercase;
    margin: 17px 0 9px 0;
    display: block;
}
.fact-section4 {
    padding-top: 100px;
    padding-bottom: 100px;
}
@media only screen and (max-width: 600px) {
.left-bnnr-section-fact {
    margin-top: 0px;
}
.Banner-Heading-fact {
    line-height: 40px;
    margin-bottom: 17px;
    text-transform: uppercase;
}
.left-bnnr-section-fact p {
    margin-bottom: 30px;
}
.benefits-content {
    padding-bottom: 40px !important;
}
.benefits-content-trucking {
    padding-top: 20px !important;
}
}
@media only screen and (max-width: 992px) {
.paragraph3 {
    margin-top: 0px;
}
.left-bnnr-section-fact {
    margin-top: 16px;
}
.Banner-Heading-fact {
    line-height: 39px;
    margin-bottom: 18px;
}
}
@media only screen and (max-width: 1200px) {
    .left-bnnr-section-fact {
        margin-top: 30px;
    }
}