.Logo {
    width: 30%;
    margin-top: 18px;
}
#menu {
    padding-left: 0;
    list-style: none;
    text-align: right;
    margin-top: 30px;
    margin-top: 69px;
}
#menu a {
    display: inline-block;
    margin-right: 0;
    margin-left: 40px;
    color: #3a3a3c;
    text-decoration: none;
    font-weight: bolder;
}

.logo-section {
    box-shadow: 4px 22px 45px -20px #000;
}
 .closebtn {
    display: none;
}
  .barrs{
  display: none;
  }
/* Responsive  */
@media only screen and (max-width: 600px) {
  .sidepanel {
      position: fixed;
      z-index: 999999;
      height: 100%;
      top: 0;
      left: 0px;
      background-color:#0079a5;
      padding-top: 60px;
      display: none;
  }
  .sidepanel a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #fff !important;
    display: flex !important; 
    transition: 0.3s;
    font-family: poppins;
    margin-left: 0px !important;

  }
  .sidepanel a:hover {
    color: #f1f1f1;
  }
  .sidepanel .closebtn {
    position: absolute;
    top: 9px;
    right: 20px;
    font-size: 36px;
    display: block;
    background: transparent;
    color: #fff;
    border: none;
  }
  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
  }
  .openbtn:hover {
    background-color:#444;
  }
  img {
    width: inherit;
  }
  .barrs{
    display: block;
  }
  .menu-bars {
    margin-top: 20px;  
  }
 .barrs {
    display: block;
    position: absolute;
    top: 10px;
    width: 42px;
    color: #fff;
    left: 10px;
    background-color: transparent;
    border: transparent;
    font-size: 26px;
}
}
@media only screen and (max-width: 992px) {

  .Logo {
    margin-bottom: 16px;
}
}
@media only screen and (max-width: 1200px) {
  #menu{
      margin-top: 40px !important;
  }
}

