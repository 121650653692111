.subscription-section {
    text-align: center;
    padding: 80px 0;
}
.sub-heading {
    margin-bottom: 30px;
    line-height: 50px;
}
.small-text {
    font-size: 14px;
    width: 90%;
    margin: auto;
}
.Subfrm {
    width: 70%;
    margin: 75px auto 0 auto;
}

.Name-field {
    width: calc(100% - 40%);
    background-color: #6e6e6e;
    color: #fff;
    padding-left: 21px;
}
.Submit-button {
    width: calc(100% - 85%);
    background-color: #0079A5;
    color: #fff;
}
.Subfrm input {
    height: 64px;
    border: 1px solid transparent;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}
/* Responsive  */
@media only screen and (max-width: 600px) {
.Subfrm {
    width: 90%;
    margin: 35px auto 0 auto;
}
.Submit-button {
    width: calc(100% - 67%) !important;
}
.subscription-section {
    padding: 50px 0;
}
}