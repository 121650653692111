.banner-container {
    overflow: hidden;
}
.Banner-Heading {
    margin-bottom: 40px;
}
.banner-section {
    background: #0079a5;
    color: #fff;
    padding: 50px 50px;
    position: absolute;
    width: 125%;
    left: 0px;
    top: 98px;
}
.Banner-head {
    position: relative;
}
/* Responsive  */
@media only screen and (max-width: 600px) {
    .banner-section {
        background: rgba(0,121,165,0.8);
        color: #fff;
        padding: 63px 30px !important;
        position: absolute;
        width: 91%;
        left: 27px;
        top: 0px !important;
        height: 254px !important;
    }
    .banner-section {
        height: 239px;
    }
}
@media only screen and (max-width: 992px){
    .banner-section{
    padding: 30px 30px;
    top:22px;
}
.Banner-Heading {
    margin-bottom: 14px;
    font-size: 20px;
}
.banner-section .text-banner{
    font-size: 15px;
}
}
@media only screen and (max-width: 1200px){
  .banner-section{
    top: 25px;
    }
}

