.left-quote-icon {
    height: 40px;
    fill: #fff;
}
.testimonial-section {
    background-color: #0079A5;
    color: #fff;
    padding: 30px 40px;
    text-align: justify;
}
.testimonial-heading{
    font-size: 22px;
    font-weight: bold;
}
.reviews{
    font-size: 16px;
}
.name{
    margin-top:20px;
    font-size:16px ;
}
.next-dots{
    height: 20px;
    width: 20px;
    margin: 0 3px;
    cursor: pointer;

}
.next-dots::before {
    content: "";
    height: 6px;
    width: 6px;
    background-color: #fff;
    display: inline-block;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    
}
.next-dots:hover::before{
    background-color: #0079A5;
}
    
span[data-quote="${active}"]::before {
       background-color: #0079A5;
   }