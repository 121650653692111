.banner-abt-image {
    width: 100%;
}
.abt-bnner-txt {
    font-size: 34px;
    font-family: poppins;
    line-height: 42px;
    color: #fff;
    margin-top: 15px;
    width: 80%;
    margin-bottom: 20px;
}
.abt-bnner-text {
    color: #fff;
    margin-bottom: 38px;
}
.buttn {
    background-color: #0079A5;
    border: 1px solid #0079A5;
    color: #fff;
    padding: 11px 30px;
    border-radius: 6px;
}
.left-abt-bnner {
    padding-top: 59px;
}
.abt-img-sec2 {
    width: 100%;
}
.abt-para {
    margin-top: 20px;
}
.abt-right-image {
    margin: 80px auto;
}
.ab-backgound {
    background-color: #f7f7f7;
    background: url(/public/images/abt-bnr4.png); 
    background-size: cover;
}
.left-abt-bnner {
    padding-top: 80px;
    padding-bottom: 90px;
}
.team-li .Headings {
    text-align: center;
    margin-bottom: 30px;
}
.team-para {
    width: 70%;
    margin: auto;
        margin-bottom: auto;
    text-align: center;
    margin-bottom: 60px;
}
.member {
    margin: auto;
    text-align: center;
}
.member img {
    width: 60%;
    background-color: #f4f4f4;
    border-radius: 50%;
    padding: 20px 0 0 0;
    margin: auto;
}
.member-name {
    margin-top: 10px;
    font-weight: bold;
}
.Team-leaders {
    padding-bottom: 80px;
}
.abt-text-sec {
    margin-top: 50px;
}
.abt-text-sec-right{
    margin-top:80px
}
.Grey-Background-Section {
    background-color: #F4F4F4;
    padding-top: 80px;
    padding-bottom: 80px;
}
.abt-right-image2 {
    margin-top: 80px;
}
@media only screen and (max-width: 600px) {
    .buttn {
        width: 40% !important;
    }
    .abt-bnner-txt{
        width: 90%;
    }
    .ab-backgound {
        background-size: cover;
    }
}
@media only screen and (max-width: 992px) {
.buttn {
    margin-top: 30px;
    width: 40% !important;
}
.ab-backgound {
    background-size: cover;
}
}
@media only screen and (max-width: 1200px) {
.abt-bnr-bttn.buttn{
    width: 30%;
}
.ab-backgound{
    background-size: cover;
}
}